import {
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';

import { EntityDataService } from '@ngrx/data';
import { LinkedInAdAccountDataService } from './ad-account/in-ad-account-data.service';
import { LinkedInAdAccountsEntityService } from './ad-account/in-ad-accounts-entity.service';
import {
	LINKEDIN_AD_ACCOUNT_ENTITY,
	LINKEDIN_ADFORMAT_ENTITY,
	LINKEDIN_CAMPAIGN_ENTITY,
	LINKEDIN_CAMPAIGN_GROUP_ENTITY,
	LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY,
} from 'src/app/state/app.state';
import { LinkedInCampaignDataService } from './campaign/in-campaign-data.service';
import { LinkedInCampaignEntityService } from './campaign/in-campaign-entity.service';
import { LinkedInCampaignGroupDataService } from './campaign-group/in-campaign-group-data.service';
import { LinkedInCampaignGroupsEntityService } from './campaign-group/in-campaign-group-entity.service';
import { LinkedInAdFormatDataService } from './ad-format/in-ad-format-data.service';
import { LinkedInAdFormatsEntityService } from './ad-format/in-ad-formats-entity.service';
import { LinkedInCampaignPlacementDataService } from './campaign-placement/in-campaign-placement-data.service';
import { LinkedInCampaignPlacementsEntityService } from './campaign-placement/in-campaign-placement-entity.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		LinkedInAdAccountDataService,
		LinkedInAdAccountsEntityService,
		LinkedInCampaignDataService,
		LinkedInCampaignEntityService,
		LinkedInCampaignGroupDataService,
		LinkedInCampaignGroupsEntityService,
		LinkedInAdFormatDataService,
		LinkedInAdFormatsEntityService,
		LinkedInCampaignPlacementDataService,
		LinkedInCampaignPlacementsEntityService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class EntityLinkedInModule {
	private linkedInAdAccountDataService: LinkedInAdAccountDataService;
	private linkedInCampaignDataService: LinkedInCampaignDataService;
	private linkedInCampaignGroupDataService: LinkedInCampaignGroupDataService;
	private linkedInAdFormatDataService: LinkedInAdFormatDataService;
	private linkedInCampaignPlacementDataService: LinkedInCampaignPlacementDataService;

	constructor(
		entityDataService: EntityDataService,
		private injector: Injector,
	) {
		this.linkedInAdAccountDataService = this.injector.get(
			LinkedInAdAccountDataService,
		);

		entityDataService.registerService(
			LINKEDIN_AD_ACCOUNT_ENTITY,
			this.linkedInAdAccountDataService,
		);

		this.linkedInCampaignDataService = this.injector.get(
			LinkedInCampaignDataService,
		);

		entityDataService.registerService(
			LINKEDIN_CAMPAIGN_ENTITY,
			this.linkedInCampaignDataService,
		);

		this.linkedInCampaignGroupDataService = this.injector.get(
			LinkedInCampaignGroupDataService,
		);

		entityDataService.registerService(
			LINKEDIN_CAMPAIGN_GROUP_ENTITY,
			this.linkedInCampaignGroupDataService,
		);

		this.linkedInAdFormatDataService = this.injector.get(
			LinkedInAdFormatDataService,
		);

		entityDataService.registerService(
			LINKEDIN_ADFORMAT_ENTITY,
			this.linkedInAdFormatDataService,
		);
		this.linkedInCampaignPlacementDataService = this.injector.get(
			LinkedInCampaignPlacementDataService,
		);

		entityDataService.registerService(
			LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY,
			this.linkedInCampaignPlacementDataService,
		);
	}
}
