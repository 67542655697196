import { Injectable, OnDestroy } from '@angular/core';
import {
	AdFormatEnum,
	IAllowedValueVM,
	IDetailedAdVM,
	IPlacementOptionsVM,
	IPublishLogItemVM,
	IPublishLogPlacementVM,
	ITiktokAdVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { TiktokAdContentIdEnum } from '../../../ad/enums/tt-ad-content-id.enum';
import { ITiktokSinglePlacementsMetaDataMap } from 'src/app/presentation/view-models/tiktok/placement/tt-placement-metadata.vm';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { IAdContentDictionary } from 'src/app/core/models';
import { IPlacementTemplatesCoreVM } from 'src/app/presentation/view-models/shared/placement-core.vm';

@Injectable({ providedIn: 'root' })
export class TiktokPlacementMetaDataMappersService implements OnDestroy {
	constructor(private appFeatureService: AppFeatureServices) {}
	private unsubscribe$ = new Subject<void>();

	public fromAdtoPlacementsMetaData<T extends IPlacementTemplatesCoreVM>(
		ad: ITiktokAdVM | IDetailedAdVM,
		originPlacements$: Observable<T[]>,
	): ITiktokSinglePlacementsMetaDataMap {
		const adContentProperties = {
			adGroupId: ad?.adGroupId,
			adFormatId: ad?.adFormatId,
			adId: ad.id,
			textValue: this.mapContentValue(
				TiktokAdContentIdEnum.text,
				ad.defaultContent,
			),
			targetUrl: this.mapContentValue(
				TiktokAdContentIdEnum.targetUrl,
				ad.defaultContent,
			),
		};

		const placementIds: string[] = [];
		const allowedValues: IAllowedValueVM[] = [];

		originPlacements$
			.pipe(
				takeUntil(this.unsubscribe$),
				map((placements) =>
					placements.filter((placement) =>
						ad.placements.some(
							(adPlacement) =>
								adPlacement.placementId === placement.id,
						),
					),
				),
			)
			.subscribe((filteredPlacements) => {
				filteredPlacements.forEach((placement) => {
					placementIds.push(placement.id);
				});
			});

		const callToAction = this.mapCTAValue(ad.defaultContent);

		return placementIds.reduce<ITiktokSinglePlacementsMetaDataMap>(
			(
				result: ITiktokSinglePlacementsMetaDataMap,
				placementId: string,
			) => {
				result[placementId] = {
					...adContentProperties,
					placementId,
					callToAction,
					creative: {
						...this.appFeatureService.studioFeature.creativePickerService.loadAdCreativeForPlacement(
							placementId,
							ad.adCreatives,
						),
						renderingOption: this.mapRenderingOption(
							ad?.adFormatId,
						),
					},
				};
				return result;
			},
			{},
		);
	}

	public fromAdLogtoPlacementsMetaData(
		adLog: IPublishLogItemVM,
	): ITiktokSinglePlacementsMetaDataMap {
		const adContentProperties = {
			adGroupId: undefined,
			adFormatId: adLog.adFormat.id as AdFormatEnum,
			adId: adLog.adId,
			textValue: this.mapContentValueFromAdLogPlacements(
				adLog.placements,
				TiktokAdContentIdEnum.text,
			),
			targetUrl: undefined,
		};

		const placements$ =
			adLog.adFormat.id === AdFormatEnum.singleImage
				? this.appFeatureService.tiktokFeature.placement.singleImage.loadPlacements()
				: this.appFeatureService.tiktokFeature.placement.singleVideo.loadPlacements();

		const placementIds: string[] = [];

		placements$
			.pipe(
				takeUntil(this.unsubscribe$),
				map((placements: IPlacementOptionsVM[]) =>
					placements.filter((placement) =>
						adLog.placements.some(({ id }) => id === placement.id),
					),
				),
			)
			.subscribe((filteredPlacements) => {
				filteredPlacements.forEach((placement) => {
					placementIds.push(placement.id);
				});
			});

		const callToAction = this.mapCTAValueFromAdLogPlacements(
			adLog.placements,
		);

		return placementIds.reduce<ITiktokSinglePlacementsMetaDataMap>(
			(
				result: ITiktokSinglePlacementsMetaDataMap,
				placementId: string,
			) => {
				result[placementId] = {
					...adContentProperties,
					placementId,
					callToAction,
					creativeUrl: adLog.placements[0].creativesReference,
					creative: {
						renderingOption: this.mapRenderingOption(
							adLog.adFormat.id as AdFormatEnum,
						),
					},
				};
				return result;
			},
			{},
		);
	}

	private mapRenderingOption(adFormatId: AdFormatEnum): MediaTypeEnum {
		if (adFormatId === AdFormatEnum.singleImage) {
			return MediaTypeEnum.Image;
		} else {
			return MediaTypeEnum.Video;
		}
	}

	private mapContentValue(
		adContentId: TiktokAdContentIdEnum,
		adDefaultContent: IAdContentDictionary,
	): string | undefined {
		return adDefaultContent ? adDefaultContent[adContentId] : undefined;
	}

	private mapContentValueFromAdLogPlacements(
		logPlacements: IPublishLogPlacementVM[],
		contentEnum: TiktokAdContentIdEnum,
	): string {
		for (const placement of logPlacements) {
			if (placement.content[contentEnum]) {
				return placement.content[contentEnum];
			}
		}
	}

	private mapCTAValue(
		adDefaultContent: IAdContentDictionary,
	): string | undefined {
		const isDynamicCTA =
			adDefaultContent?.[TiktokAdContentIdEnum.callToAction] ===
			TiktokAdContentIdEnum.dynamicCallToAction;
		const dynamicCallToActionArray =
			adDefaultContent?.[TiktokAdContentIdEnum.dynamicCallToAction];

		if (isDynamicCTA && dynamicCallToActionArray?.length > 0) {
			return dynamicCallToActionArray[0]?.name;
		}

		const ctaId =
			adDefaultContent?.[TiktokAdContentIdEnum.standardCallToAction];
		return ctaId;
	}

	private mapCTAValueFromAdLogPlacements(
		adLogPlacements: IPublishLogPlacementVM[],
	): string | undefined {
		const dynamicCallToActionArray =
			adLogPlacements[0].content?.[
				TiktokAdContentIdEnum.dynamicCallToAction
			];

		if (
			Array.isArray(dynamicCallToActionArray) &&
			dynamicCallToActionArray.length > 0
		) {
			return dynamicCallToActionArray[0]?.name;
		}

		return adLogPlacements[0].content?.[
			TiktokAdContentIdEnum.standardCallToAction
		];
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
