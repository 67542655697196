export const SOCIAL_SLICE = 'social';
export const AD_ENTITY = 'Ad';
export const AD_SCHEDULE_ENTITY = 'AdSchedule';
export const AD_LOG_ENTITY = 'AdLog';
export const AD_CONTENT_VALIDATION_ENTITY = 'AdContentValidation';
export const AD_CONTENT_CAROUSEL_VALIDATION_ENTITY =
	'AdContentCarouselValidation';
export const AD_CUSTOMIZED_VALIDATION_ENTITY = 'AdCustomizedValidation';
export const AD_STATUS_ENTITY = 'AdStatus';
export const ADGROUP_ENTITY = 'AdGroup';
export const ADGROUP_CONNECTION_ENTITY = 'AdGroupConnection';
export const AD_PLACEMENT_TEMPLATE_ENTITY = 'AdPlacementTemplate';
export const CAMPAIGN_ENTITY = 'Campaign';
export const CREATIVE_SET_ENTITY = 'CreativeSet';
export const CREATIVE_SET_FOLDER_ENTITY = 'CreativeSetFolder';
export const FACEBOOK_ADACCOUNT_ENTITY = 'FacebookAdAccount';
export const FACEBOOK_ADFORMAT_ENTITY = 'FacebookAdFormat';
export const FACEBOOK_ADSET_ENTITY = 'FacebookAdSet';
export const FACEBOOK_ADSET_PLACEMENT_ENTITY = 'FacebookAdSetPlacement';
export const FACEBOOK_CAMPAIGN_ENTITY = 'FacebookCampaign';
export const FACEBOOK_PAGE_ENTITY = 'FacebookPage';
export const FACEBOOK_INSTAGRAM_ACCOUNT_ENTITY = 'FacebookInstagramAccount';
export const FACEBOOK_SINGLE_PLACEMENT_ENTITY = 'FacebookSinglePlacement';
export const FACEBOOK_CAROUSEL_PLACEMENT_ENTITY = 'FacebookCarouselPlacement';
export const FACEBOOK_CTA_ENTITY = 'FacebookCta';

export const INTEGRATION_ENTITY = 'Integration';
export const INTEGRATION_CONNECTION_STATUS_ENTITY =
	'IntegrationConnectionStatus';
export const LOCALIZATION_ENTITY = 'Localization';
export const TIMEZONE_ENTITY = 'TimeZone';
export const NETWORK_ENTITY = 'Network';
export const PUBLISHLOG_ENTITY = 'PublishLog';
export const TIKTOK_AD_FORMAT_ENTITY = 'TiktokAdFormat';
export const TIKTOK_AD_ACCOUNT_ENTITY = 'TiktokAdAccount';
export const TIKTOK_AD_GROUP_ENTITY = 'TiktokAdGroup';
export const TIKTOK_AD_GROUP_PLACEMENT_ENTITY = 'TiktokAdGroupPlacement';
export const TIKTOK_CAMPAIGN_ENTITY = 'TiktokCampaign';
export const TIKTOK_IDENTITY_ENTITY = 'TiktokIdentity';
export const TIKTOK_SINGLE_IMAGE_PLACEMENT_ENTITY =
	'TiktokSingleImagePlacement';
export const TIKTOK_SINGLE_VIDEO_PLACEMENT_ENTITY =
	'TiktokSingleVideoPlacement';
export const TIKTOK_CTA_ENTITY = 'TiktokCta';
export const LINKEDIN_AD_ACCOUNT_ENTITY = 'LinkedInAdAccount';
export const LINKEDIN_CAMPAIGN_ENTITY = 'LinkedInCampaign';
export const LINKEDIN_CAMPAIGN_GROUP_ENTITY = 'LinkedInCampaignGroup';
export const LINKEDIN_ADFORMAT_ENTITY = 'LinkedInAdFormat';
export const LINKEDIN_CAMPAIGN_PLACEMENT_ENTITY = 'LinkedInCampaignPlacement';

export const USER_ENTITY = 'User';

// Extended by lazy loaded modules
export interface State {}
