import { Injectable } from '@angular/core';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import {
	AdContentIdEnum,
	FacebookSupportedPlacements,
} from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import { FacebookPlacementService } from '../../../placement/services/fb-placement.service';
import { AdService } from 'src/app/features/ad/services/ad/ad.service';
import { AdValidationService } from 'src/app/features/ad/services/ad-validation/ad-validation.service';
import { AdMappersService } from 'src/app/features/ad/mappers/ad-mapper/ad.mapper';
import {
	AdContentTemplateScopeEnum,
	AdFormatEnum,
	IAdContentTemplateVM,
	IFacebookAdVM,
	IFacebookCarouselCardVM,
	IFacebookPlacementDictionary,
	IFacebookPlacementVM,
	IPlacementBaseVM,
	IPlacementOptionsVM,
} from 'src/app/presentation/view-models';
import { FacebookAdMappersService } from '../../mappers/ad-mapper/fb-ad.mapper';
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { IAd } from 'src/app/core/models';
import { FacebookBulkAdService } from './fb-bulk-ad.service';
import { AdPlacementTemplateService } from 'src/app/features/ad/services/ad-placement-template/ad-placement-template.service';
import { BulkAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/bulk-ad.mapper';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdService extends AdService {
	public placementId: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	constructor(
		public appEntityServices: AppEntityServices,
		public adMapper: AdMappersService,
		public fbAdMapper: FacebookAdMappersService,
		public facebookPlacementService: FacebookPlacementService,
		public adPlacementTemplateService: AdPlacementTemplateService,
		public adValidationService: AdValidationService,
		public bulk: FacebookBulkAdService,
		public bulkMapper: BulkAdMapperService,
	) {
		super(appEntityServices, adValidationService, adMapper, bulkMapper);
	}

	public loadById(adId: string): Observable<IFacebookAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		return ad$.pipe(map((ad) => this.fbAdMapper.toAdVM(ad)));
	}

	public loadByIds(adIds: string[]): Observable<IFacebookAdVM[]> {
		const adIds$: Observable<string[]> = of(adIds);

		if (adIds.length === 0) {
			return of([]);
		}

		return adIds$.pipe(
			switchMap((ids) =>
				combineLatest(ids.map((adId) => this.loadById(adId))),
			),
		);
	}

	public loadAdsByAdGroupId(adGroupId: string): Observable<IFacebookAdVM[]> {
		const adIds$: Observable<string[]> =
			this.loadAdsIdsByAdGroupId(adGroupId);

		return adIds$.pipe(switchMap((ids) => this.loadByIds(ids)));
	}

	public loadAdWithPlacementsByAdId(adId: string): Observable<IFacebookAdVM> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);

		const placements$: Observable<IFacebookPlacementVM[]> =
			this.facebookPlacementService.loadPlacementsByAdId(adId);

		return this.returnAdWithPlacementsFromDifferentFormats(
			ad$,
			placements$,
		);
	}

	private returnAdWithPlacementsFromDifferentFormats(
		ad$: Observable<IAd>,
		placements$: Observable<IFacebookPlacementVM[]>,
	): Observable<IFacebookAdVM> {
		return combineLatest([ad$, placements$]).pipe(
			map(([ad, placements]) =>
				this.loadAdWithPlacementsMetaData(ad, placements),
			),
		);
	}

	public saveAd(
		adVM: IFacebookAdVM,
		placements: IPlacementOptionsVM[] = null,
	): Observable<IAd> {
		const ad =
			adVM.adFormatId === AdFormatEnum.carousel
				? this.fbAdMapper.carousel.toAd(adVM)
				: this.fbAdMapper.single.toAd(adVM, placements);
		return this.appEntityServices.adEntity.ad.update(ad);
	}

	public addAd(
		fromViewModel: IFacebookAdVM,
		placements: IPlacementOptionsVM[],
	): Observable<IAd> {
		const ad: IAd = this.fbAdMapper.single.toAd(fromViewModel, placements);
		return this.appEntityServices.adEntity.ad.add(ad);
	}

	private loadAdWithPlacementsMetaData(
		ad: IAd,
		placements: IPlacementBaseVM[],
	): IFacebookAdVM {
		const adPlacementIds = ad.placements.map(
			(placement) => placement.placementId,
		);
		const supportedPlacements = placements.filter((placement) =>
			adPlacementIds?.includes(placement.id),
		);
		return this.fbAdMapper.toAdVM(ad, supportedPlacements);
	}

	public addDefaultTargetUrlToAd(
		selectedAd: IFacebookAdVM,
		targetUrl: string,
		cardIndex?: number,
	): IFacebookAdVM {
		if (selectedAd.content) {
			const existingWebSiteUrl = this.getExistingUrlFromAdContent(
				selectedAd.content,
			);

			if (existingWebSiteUrl || existingWebSiteUrl === undefined) {
				return selectedAd;
			}

			selectedAd = this.updateUrls(selectedAd, targetUrl);
			this.adValidationService.validateWebsiteUrlForPlacements(
				selectedAd,
				targetUrl,
			);
		} else {
			const existingCardUrl = this.getExistingUrlForCarousel(
				selectedAd.carouselContent.carouselCards,
				cardIndex,
			);

			if (existingCardUrl || existingCardUrl === undefined) {
				return selectedAd;
			}

			selectedAd = this.updateCardUrl(selectedAd, targetUrl, cardIndex);
		}

		return selectedAd;
	}

	public loadCarouselContentTemplates(
		placements: IFacebookPlacementVM[],
		scope: AdContentTemplateScopeEnum,
	): Observable<IAdContentTemplateVM[]> {
		return of(
			this.fbAdMapper.carousel.getContentTemplates({}, placements, scope),
		);
	}

	public checkIfAdHasSavedContent(adId: string): Observable<boolean> {
		return this.appEntityServices.adEntity.ad.entities$.pipe(
			map((ads) => ads.find((ad) => ad.id === adId)),
			map((ad) => this.checkIsAnyAdContent(ad.default.content)),
		);
	}

	public checkIfAdHasCustomizedPlacement(
		adId: string,
		placementId: string,
	): Observable<boolean> {
		const ads$: Observable<IAd[]> =
			this.appEntityServices.adEntity.ad.entities$;

		const ad$: Observable<IAd> = ads$.pipe(
			filter((ads) => !!ads.length),
			map((ads) => ads.find((ad) => ad.id === adId)),
			filter((ad) => !!ad),
		);
		const isCustomized$ = ad$.pipe(
			map((ad) =>
				ad?.placements?.find(
					(placement) => placement.placementId === placementId,
				),
			),
			filter((placement) => !!placement),
			map((placement) => placement.isCustomized),
		);

		return isCustomized$;
	}

	public checkIfAdHasCustomizedPlacementByAd(
		ad: IFacebookAdVM,
		placementId: string,
	): Observable<boolean> {
		const isCustomized$ = of(ad).pipe(
			map(({ placements }) =>
				placements?.find(
					(placement) => placement.placementId === placementId,
				),
			),
			filter((placement) => !!placement),
			map((placement) => placement.isCustomized),
		);

		return isCustomized$;
	}

	public loadUniqueCreativeIdsByAdPlacements(ads: IAd[]): string[] {
		let creativeIds: string[] = [];
		ads.forEach((ad) => {
			if (ad.adFormatId === AdFormatEnum.carousel) {
				creativeIds = [
					...creativeIds,
					...this.fbAdMapper.carousel.getCreativeIdsFromPlacementCards(
						ad.placements[0],
					),
				];
			}
			if (ad.adFormatId === AdFormatEnum.singleImageAndVideo) {
				creativeIds = [
					...creativeIds,
					...this.fbAdMapper.single.getCreativeIdsFromPlacements(
						ad.placements,
					),
				];
			}
		});

		return creativeIds.filter(
			(value, index, self) => self.indexOf(value) === index,
		);
	}

	private updateUrls(ad: IFacebookAdVM, url: string): IFacebookAdVM {
		Object.keys(ad.content).forEach((key) => {
			const webSiteContent = ad.content[key].adContentTemplates.find(
				(adContent) => adContent.id === AdContentIdEnum.websiteUrl,
			);

			if (webSiteContent) {
				webSiteContent.value = url;
			}
		});

		return ad;
	}

	private updateCardUrl(
		ad: IFacebookAdVM,
		url: string,
		cardIndex: number,
	): IFacebookAdVM {
		const webSiteContent = ad.carouselContent.carouselCards[
			cardIndex
		].cardContentTemplates.find(
			(content) => content.id === AdContentIdEnum.websiteUrl,
		);

		if (webSiteContent) {
			webSiteContent.value = url;
		}

		return ad;
	}

	private getExistingUrlFromAdContent(
		placements: IFacebookPlacementDictionary,
	): string {
		let existingWebSiteUrl;
		Object.keys(placements).every((key) => {
			existingWebSiteUrl = placements[key].adContentTemplates.find(
				(adContent) => adContent.id === AdContentIdEnum.websiteUrl,
			)?.value;
			return !existingWebSiteUrl;
		});
		return existingWebSiteUrl;
	}

	private getExistingUrlForCarousel(
		cards: IFacebookCarouselCardVM[],
		cardIndex: number,
	): string {
		return cards[cardIndex].cardContentTemplates.find(
			(content) => content.id === AdContentIdEnum.websiteUrl,
		)?.value;
	}
}
