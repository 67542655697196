import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILinkedInCampaign } from 'src/app/core/models/linkedin/in-campaign.model';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { BaseFeatureService } from 'src/app/features/base-feature.service';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignBaseService extends BaseFeatureService<ILinkedInCampaign> {
	constructor(public appEntityServices: AppEntityServices) {
		super();
	}

	public getWithQuery(params: {
		integrationId: string;
		adAccountId: string;
		campaignGroupId: string;
	}): void {
		this.appEntityServices.linkedInEntity.campaign.getWithQuery(params);
	}

	protected getById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected getByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
	protected load(): Observable<ILinkedInCampaign> {
		throw new Error('Method not implemented.');
	}
	protected loadAll(): Observable<ILinkedInCampaign[]> {
		throw new Error('Method not implemented.');
	}
	protected loadById(_id: string): Observable<ILinkedInCampaign> {
		throw new Error('Method not implemented.');
	}
	protected loadByIds(_ids: string[]): Observable<ILinkedInCampaign[]> {
		throw new Error('Method not implemented.');
	}
	protected deleteById(_id: string): void {
		throw new Error('Method not implemented.');
	}
	protected deleteByIds(_ids: string[]): void {
		throw new Error('Method not implemented.');
	}
}
