import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAllowedValue } from 'src/app/core/models';
import {
	AdContentIdEnum,
	FacebookSupportedPlacements,
} from '../../../shared/enums';
import { UIModule } from '@bannerflow/ui';
import { CtaButtonComponent } from '../../../shared/cta-button/cta-button.component';

@Component({
	standalone: true,
	imports: [UIModule, CtaButtonComponent],
	selector: 'stories-footer',
	templateUrl: './stories-footer.component.html',
	styleUrls: ['./stories-footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoriesFooterComponent {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;

	@Input() public ctaValue: string;
	@Input() public placementId: FacebookSupportedPlacements;
}
