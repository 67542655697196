import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ConfigService } from 'src/app/core/config/config.service';
import { ApiService } from '../../../internal/api.service';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ITiktokCampaign } from 'src/app/core/models';
import { ILinkedInCampaignGroup } from 'src/app/core/models/linkedin/in-campaign-group.model';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignGroupsApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/linkedin`);
	}

	/**
	 * Get campaign groups
	 * @param integrationId
	 * @param adAccountId
	 */
	public getCampaignGroups(
		integrationId: string,
		adAccountId: string,
	): Observable<ILinkedInCampaignGroup[]> {
		const endpoint = `integrations/${integrationId}/ad-accounts/${adAccountId}/campaign-groups`;
		const url = `${this.baseUrl}/${endpoint}`;

		return this.http.get<ILinkedInCampaignGroup[]>(url).pipe(
			map((campaignGroups) =>
				campaignGroups.map((campaign) => ({
					...campaign,
					integrationId,
					adAccountId,
				})),
			),
			catchError(ApiService.handleError),
		);
	}
}
