import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AdContentIdEnum, FacebookSupportedPlacements } from '../enums';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { AppFeatureServices } from 'src/app/features/app-feature.service';
import { filter, map, Observable, tap } from 'rxjs';

@Component({
	standalone: true,
	imports: [CommonModule, UIModule],
	selector: 'cta-button',
	templateUrl: './cta-button.component.html',
	styleUrls: ['./cta-button.component.scss'],
})
export class CtaButtonComponent implements OnChanges {
	public adContentId: typeof AdContentIdEnum = AdContentIdEnum;
	public supportedPlacementId: typeof FacebookSupportedPlacements =
		FacebookSupportedPlacements;
	@Input() public ctaValue: string;
	@Input() public placementId: FacebookSupportedPlacements;
	@Input() public isExpanded?: boolean;
	public ctaName$: Observable<string>;

	constructor(private appFeatureService: AppFeatureServices) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.ctaValue) {
			this.ctaName$ = this.appFeatureService.facebookFeature.account.cta
				.loadAllCtas()
				.pipe(
					filter((ctas) => !!ctas),
					map(
						(ctas) =>
							ctas.find((cta) => cta.id === this.ctaValue)?.name,
					),
				);
		}
	}
}
