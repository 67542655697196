import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AppEntityServices } from 'src/app/entities/app-entity-service';
import { LinkedInCampaignBaseService } from './in-campaign-base.service';
import { ILinkedInAdAccount } from 'src/app/core/models/linkedin/in-ad-account.model';
import { LinkedInAdAccountMapperService } from '../../mappers/in-ad-account.mapper';
import { LinkedInCampaignMapperService } from '../../mappers/in-campaign.mapper.ts';
import { ILinkedInCampaignVM } from 'src/app/presentation/view-models/linkedin/account/in-campaign.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignService extends LinkedInCampaignBaseService {
	constructor(
		public appEntityServices: AppEntityServices,
		private campaignMapper: LinkedInCampaignMapperService,
	) {
		super(appEntityServices);
	}

	public loadAllByCampaignGroupId(
		campaignGroupId: string,
	): Observable<ILinkedInCampaignVM[]> {
		const campaigns$: Observable<ILinkedInCampaignVM[]> =
			this.appEntityServices.linkedInEntity.campaign.entities$;

		return campaigns$.pipe(
			filter((campaigns) => !!campaigns),
			map((campaigns) =>
				campaigns.filter(
					(campaign) => campaign.campaignGroupId === campaignGroupId,
				),
			),
			map((groups) =>
				groups.map((group) => this.campaignMapper.toCampaignVM(group)),
			),
		);
	}

	public isLoadingCampaigns(): Observable<boolean> {
		return this.appEntityServices.linkedInEntity.campaign.loading$;
	}
}
