import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { BaseApiService } from 'src/app/core/base-api-service';
import { ConfigService } from 'src/app/core/config/config.service';
import { IPlacementOptions } from 'src/app/core/models';
import { AdFormatEnum, NetworkEnum } from 'src/app/presentation/view-models';
import { ApiService } from '../../internal/api.service';
import { ICta } from 'src/app/core/models/shared/cta.model';

@Injectable({
	providedIn: 'root',
})
export class CtaApiService extends BaseApiService {
	constructor(public http: HttpClient) {
		super(http, `${ConfigService?.config?.SCS_URL}/api/calls-to-action`);
	}

	/**
	 * Gets Ctas based on networkId
	 */
	public getCtas(networkId: NetworkEnum): Observable<ICta[]> {
		let params = new HttpParams();

		params = params.append('networkId', networkId);

		const url = this.baseUrl;

		return this.http.get<ICta[]>(url, { params: params }).pipe(
			map((ctas) => ctas),
			catchError(ApiService.handleError),
		);
	}
}
