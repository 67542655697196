import { Injectable } from '@angular/core';
import { ILinkedInCampaign } from 'src/app/core/models/linkedin/in-campaign.model';
import { ILinkedInCampaignVM } from 'src/app/presentation/view-models/linkedin/account/in-campaign.vm';

@Injectable({
	providedIn: 'root',
})
export class LinkedInCampaignMapperService {
	public toCampaignVM(campaign: ILinkedInCampaign): ILinkedInCampaignVM {
		const campaignVM: ILinkedInCampaignVM = {
			id: campaign.id,
			name: campaign.name,
			adAccountId: campaign.adAccountId,
			integrationId: campaign.integrationId,
			campaignGroupId: campaign.campaignGroupId,
			adFormat: campaign.adFormat,
			objective: campaign.objective,
		};

		return campaignVM;
	}
}
