import { Injectable } from '@angular/core';
import { IAd } from 'src/app/core/models';
import {
	AdContentIdEnum,
	FacebookSupportedPlacements,
	FacebookSupportedPlacementIdsOrder,
} from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import {
	AdFormatEnum,
	IAdContentTemplateVM,
	IAdPlacementCustomizationVM,
	IAssignedCreativeVM,
	IAdPlacementChildVM,
	IFacebookAdPlacementVM,
	IFacebookAdVM,
	IFacebookPlacementVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';
import { FacebookCarouselSupportedPlacements } from 'src/app/presentation/features/integrations/facebook/placement/carousel/placements/enums';
import { SingleAdMapperService } from './single-ad.mapper';
import { CarouselAdMapperService } from './carousel-ad.mapper';
import { BulkAdMapperService } from '../../../../ad/mappers/ad-mapper/bulk-ad.mapper';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import { AdValidationMapperService } from 'src/app/features/ad/mappers/ad-mapper/ad-validation.mapper';
import { IFacebookAdBulkVM } from 'src/app/presentation/view-models/facebook/fb-ad-bulk.vm';
import { IMixedAdDefaultContentVM } from 'src/app/presentation/view-models/ad/ad-mixed-content.vm';
import { MIN_CARDS_AMOUNT } from 'src/app/presentation/features/integrations/facebook/placement/carousel/const/carousel-cards-limit.const';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdMappersService {
	constructor(
		public single: SingleAdMapperService,
		public carousel: CarouselAdMapperService,
		public bulk: BulkAdMapperService,
		public creativesMapper: CreativesAdMapperService,
		public validationMapper: AdValidationMapperService,
	) {}

	public toAdVM(
		ad: IAd,
		placements: IFacebookPlacementVM[] = null,
	): IFacebookAdVM {
		const setIdValues = [];
		const adViewModel: IFacebookAdVM = {} as IFacebookAdVM;

		if (ad.default?.content) {
			adViewModel.defaultContent = {
				...ad.default.content,
			};

			if (ad.adFormatId === AdFormatEnum.carousel) {
				ad.default?.cards.forEach((cardCreative) => {
					cardCreative.creatives.forEach((creative) =>
						setIdValues.push(creative?.setId),
					);
				});
				adViewModel.cardCreativesIds = setIdValues;
				adViewModel.websiteUrls = ad.default?.cards.map(
					(cardContent) =>
						cardContent?.content?.[AdContentIdEnum.websiteUrl],
				);
				adViewModel.defaultCards = this.carousel.toCarouselCardsVM(
					ad.default.cards,
				);
				adViewModel.primaryText =
					ad.default.content[AdContentIdEnum.primaryText];
			} else {
				adViewModel.primaryText =
					ad?.default?.content?.[AdContentIdEnum.primaryTexts]?.[0];
			}

			adViewModel.url =
				ad.adFormatId === AdFormatEnum.carousel
					? ad.default.content[AdContentIdEnum.seeMoreUrl] ??
						ad.default.content[AdContentIdEnum.appUrl]
					: ad.default.content[AdContentIdEnum.websiteUrl] ??
						ad.default.content[AdContentIdEnum.appUrl];

			if (ad.default.content[AdContentIdEnum.appUrl]) {
				adViewModel.websiteUrls = [];
			}

			adViewModel.carouselContent =
				ad.adFormatId === AdFormatEnum.carousel
					? this.carousel.toContentVM(
							ad.default.content,
							placements,
							ad.default.cards,
						)
					: undefined;
		}

		adViewModel.mediaType = this.mapMediaType(
			ad.default?.content
				? ad.default.creatives?.[0]?.renderingOption
				: undefined,
			ad.adFormatId,
		);
		adViewModel.adCreatives = [];

		if (ad.default?.creatives) {
			if (
				ad.adFormatId === AdFormatEnum.carousel &&
				ad.default.cards?.[0]?.creatives
			) {
				adViewModel.adCreatives = ad.default.cards[0].creatives.map(
					(creative) => this.creativesMapper.toCreativeVM(creative),
				);
			} else {
				adViewModel.adCreatives = ad.default.creatives.map((creative) =>
					this.creativesMapper.toCreativeVM(creative),
				);
			}
		}

		adViewModel.id = ad.id;
		adViewModel.adFormatId = ad.adFormatId;
		adViewModel.adGroupId = ad.adGroupId;
		adViewModel.languageId = ad.languageId;
		adViewModel.name = ad.name;

		adViewModel.content =
			ad.adFormatId === AdFormatEnum.singleImageAndVideo
				? this.single.fromAdToContentVM(ad, placements)
				: undefined;

		adViewModel.placements = this.mapAdPlacements(ad);
		adViewModel.children = this.fromAdToChildrenVM(ad);

		return adViewModel;
	}

	public toCombinedAdVM(
		ads: IAd[],
		placements: IFacebookPlacementVM[] = null,
	): IFacebookAdBulkVM {
		const minCardsAmount =
			ads[0].adFormatId === AdFormatEnum.carousel
				? MIN_CARDS_AMOUNT
				: undefined;

		const ad = this.bulk.toCombinedAd(ads, minCardsAmount);
		const mixedDefaultContent: IMixedAdDefaultContentVM =
			this.bulk.mapMixedAdDefaultContent(ads, minCardsAmount);

		const adVM = this.toAdVM(ad, placements);

		return { ...adVM, mixedDefaultContent };
	}

	private fromAdToChildrenVM(ad: IAd): IAdPlacementChildVM[] {
		const children: IAdPlacementChildVM[] = [];
		const sortedAdplacements = this.sortAdPlacements(ad.placements);
		const filterForSupportedDesign = this.getOnlyDesignedPlacements(
			sortedAdplacements,
			ad.adFormatId,
		);

		if (filterForSupportedDesign) {
			filterForSupportedDesign.forEach((placement) => {
				children.push(this.toAdChildVM(ad, placement));
			});
		}
		return children;
	}

	private toAdChildVM(ad: IAd, placement: IAdPlacement): IAdPlacementChildVM {
		return {
			adId: ad.id,
			placementId: placement.placementId,
			isCustomized: placement?.isCustomized,
			adCreatives: this.mapCreatives(ad.adFormatId, placement),
			mediaType: this.mapMediaType(
				placement?.creative?.renderingOption,
				ad.adFormatId,
			),
			languageId: ad.languageId,
			primaryText: placement.content
				? placement.content[AdContentIdEnum.primaryText]
				: undefined,
			primaryTexts: placement.content
				? placement.content[AdContentIdEnum.primaryTexts]
				: undefined,
			url: placement.content
				? this.mapUrl(ad.adFormatId, placement)
				: undefined,
			websiteUrls: ad.default.cards
				? ad.default.cards.map(
						(cardContent) =>
							cardContent?.content?.[AdContentIdEnum.websiteUrl],
					)
				: undefined,
		};
	}

	private mapCreatives(
		adFormatId: AdFormatEnum,
		placement: IAdPlacement,
	): IAssignedCreativeVM[] {
		if (adFormatId === AdFormatEnum.carousel && placement.cards?.length) {
			return placement.cards.map((card) =>
				this.creativesMapper.toCreativeVM(card.creative),
			);
		}
		if (
			adFormatId === AdFormatEnum.singleImageAndVideo &&
			placement.creative
		) {
			const creativeVM = this.creativesMapper.toCreativeVM(
				placement.creative,
			);
			return creativeVM ? [creativeVM] : [];
		}
		return [];
	}

	private mapUrl(adFormatId: AdFormatEnum, placement: IAdPlacement): string {
		if (adFormatId === AdFormatEnum.carousel) {
			return placement?.content[AdContentIdEnum.seeMoreUrl];
		}
		if (adFormatId === AdFormatEnum.singleImageAndVideo) {
			return placement?.content[AdContentIdEnum.websiteUrl];
		}
		return undefined;
	}

	private mapMediaType(
		renderingOptionTypeId: string,
		adFormatId: AdFormatEnum,
	): MediaTypeEnum {
		if (adFormatId === AdFormatEnum.carousel) {
			return MediaTypeEnum.Carousel;
		} else {
			switch (renderingOptionTypeId) {
				case MediaTypeEnum.Image:
					return MediaTypeEnum.Image;
				case MediaTypeEnum.Video:
					return MediaTypeEnum.Video;
				default:
					return;
			}
		}
	}

	public sortAdPlacements(adPlacements: IAdPlacement[]): IAdPlacement[] {
		if (!adPlacements?.sort) {
			return [];
		}

		return adPlacements
			.slice()
			.sort(
				(a, b) =>
					FacebookSupportedPlacementIdsOrder[a.placementId] -
					FacebookSupportedPlacementIdsOrder[b.placementId],
			);
	}

	public mapCustomizedContentToValidateAllProperties(
		templates: IAdContentTemplateVM[],
		customizedContent: IAdPlacementCustomizationVM,
	): string {
		const content = {};

		templates.forEach((template) => {
			let value;

			if (customizedContent?.content) {
				value = customizedContent.content[template.id];
			} else if (Array.isArray(template.value)) {
				value = template.value.map((item) => item.value);
			} else {
				value = template.value?.id || template.value;
			}

			content[template.id] = value;
		});

		return JSON.stringify(content);
	}

	private mapAdPlacements(ad: IAd): IFacebookAdPlacementVM[] {
		const sortedAdplacements = this.sortAdPlacements(ad.placements);
		const filterForSupportedDesign = this.getOnlyDesignedPlacements(
			sortedAdplacements,
			ad.adFormatId,
		);

		return filterForSupportedDesign.map((placement) =>
			this.mapAdPlacement(placement),
		);
	}

	private mapAdPlacement(adPlacement: IAdPlacement): IFacebookAdPlacementVM {
		return {
			placementId: adPlacement.placementId,
			creative: adPlacement.creative,
			isCustomized: adPlacement.isCustomized,
			cards: adPlacement.cards,
			content: adPlacement.content,
		};
	}

	private getOnlyDesignedPlacements(
		adPlacements: IAdPlacement[],
		adformat: AdFormatEnum,
	): IAdPlacement[] {
		let availablePlacementsForDesign: string[];

		switch (adformat) {
			case AdFormatEnum.singleImageAndVideo:
				availablePlacementsForDesign = Object.values(
					FacebookSupportedPlacements,
				) as string[];
				break;
			case AdFormatEnum.carousel:
				availablePlacementsForDesign = Object.values(
					FacebookCarouselSupportedPlacements,
				) as string[];
				break;
		}

		return adPlacements.filter((placement) =>
			availablePlacementsForDesign?.includes(placement.placementId),
		);
	}
}
