import { Injectable } from '@angular/core';
import { IAd, IAdContentDictionary } from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import { TiktokAdContentIdEnum } from 'src/app/presentation/features/integrations/tiktok/ad/enums/tt-ad-content-id.enum';
import {
	AdFormatEnum,
	IAdPlacementBaseVM,
	ITiktokAdVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class TiktokAdMappersService {
	constructor(private creativesMapper: CreativesAdMapperService) {}

	public toAdVM(ad: IAd): ITiktokAdVM {
		const adVM: ITiktokAdVM = {} as ITiktokAdVM;

		if (ad.default.content) {
			adVM.defaultContent = this.toDefaultContentVM(ad.default.content);
		}

		adVM.id = ad.id;
		adVM.adFormatId = ad.adFormatId;
		adVM.adGroupId = ad.adGroupId;
		adVM.languageId = ad.languageId;
		adVM.name = ad.name;
		adVM.placements = ad.placements?.map((placement) =>
			this.mapAdPlacement(placement),
		);

		if (ad.default.creatives) {
			adVM.adCreatives = ad.default.creatives.map((creative) =>
				this.creativesMapper.toCreativeVM(creative),
			);
		}

		return adVM;
	}

	public toAd(adViewModel: ITiktokAdVM): IAd {
		const ad: IAd = {
			id: adViewModel.id,
			name: adViewModel.name,
			adFormatId: adViewModel.adFormatId,
			languageId: adViewModel.languageId,
			adGroupId: adViewModel.adGroupId,
			default: {
				content: adViewModel.defaultContent
					? this.toDefaultContent(adViewModel.defaultContent)
					: null,
				creatives: adViewModel?.adCreatives
					? adViewModel.adCreatives.map((creative) =>
							this.creativesMapper.toCreative(
								creative,
								adViewModel.adFormatId ===
									AdFormatEnum.singleVideo
									? MediaTypeEnum.Video
									: MediaTypeEnum.Image,
							),
						)
					: [],
			},
		} as IAd;

		return ad;
	}

	public toDefaultContentVM(
		content: IAdContentDictionary,
	): IAdContentDictionary {
		let callToAction: string;
		let destination: string;

		if (
			!(
				this.hasContentValue(
					content,
					TiktokAdContentIdEnum.dynamicCallToAction,
				) &&
				this.hasContentValue(
					content,
					TiktokAdContentIdEnum.standardCallToAction,
				)
			)
		) {
			callToAction = this.hasContentValue(
				content,
				TiktokAdContentIdEnum.dynamicCallToAction,
			)
				? TiktokAdContentIdEnum.dynamicCallToAction
				: TiktokAdContentIdEnum.standardCallToAction;
		}

		if (
			!(
				this.hasContentValue(
					content,
					TiktokAdContentIdEnum.tiktokInstantPage,
				) &&
				this.hasContentValue(content, TiktokAdContentIdEnum.targetUrl)
			)
		) {
			destination = this.hasContentValue(
				content,
				TiktokAdContentIdEnum.tiktokInstantPage,
			)
				? TiktokAdContentIdEnum.tiktokInstantPage
				: TiktokAdContentIdEnum.targetUrl;
		}

		return {
			...content,
			...{
				[TiktokAdContentIdEnum.callToAction]: callToAction,
			},
			...{
				[TiktokAdContentIdEnum.destination]: destination,
			},
		};
	}

	private hasContentValue(
		content: IAdContentDictionary,
		contentId: TiktokAdContentIdEnum,
	): any {
		return (
			content.hasOwnProperty(contentId) &&
			content[contentId] !== undefined
		);
	}

	public toDefaultContent(
		content: IAdContentDictionary,
		savedContent?: IAdContentDictionary,
	): IAdContentDictionary {
		const newContent = { ...content };

		switch (newContent[TiktokAdContentIdEnum.callToAction]) {
			case TiktokAdContentIdEnum.standardCallToAction:
				delete newContent[TiktokAdContentIdEnum.dynamicCallToAction];
				break;
			case TiktokAdContentIdEnum.dynamicCallToAction:
				delete newContent[TiktokAdContentIdEnum.standardCallToAction];
				break;
			default:
				if (!savedContent) {
					break;
				}

				if (savedContent[TiktokAdContentIdEnum.dynamicCallToAction]) {
					delete newContent[
						TiktokAdContentIdEnum.standardCallToAction
					];
				} else if (
					savedContent[TiktokAdContentIdEnum.standardCallToAction]
				) {
					delete newContent[
						TiktokAdContentIdEnum.dynamicCallToAction
					];
				}
		}

		switch (newContent[TiktokAdContentIdEnum.destination]) {
			case TiktokAdContentIdEnum.tiktokInstantPage:
				delete newContent[TiktokAdContentIdEnum.targetUrl];
				break;
			case TiktokAdContentIdEnum.targetUrl:
				delete newContent[TiktokAdContentIdEnum.tiktokInstantPage];
				break;
			default:
				if (!savedContent) {
					break;
				}

				if (savedContent[TiktokAdContentIdEnum.tiktokInstantPage]) {
					delete newContent[TiktokAdContentIdEnum.targetUrl];
				} else if (savedContent[TiktokAdContentIdEnum.targetUrl]) {
					delete newContent[TiktokAdContentIdEnum.tiktokInstantPage];
				}
		}

		delete newContent[TiktokAdContentIdEnum.callToAction];
		delete newContent[TiktokAdContentIdEnum.destination];

		return newContent;
	}

	public mapAdContentToValidateAllProperties(
		adContent: IAdContentDictionary,
	): IAdContentDictionary {
		const adContentToValidate = {
			...adContent,
		};

		const selectedCallToAction =
			adContentToValidate[TiktokAdContentIdEnum.callToAction];
		const selectenDestination =
			adContentToValidate[TiktokAdContentIdEnum.destination];

		adContentToValidate[selectedCallToAction] = this.mapTemplateValue(
			selectedCallToAction,
			adContent,
		);
		adContentToValidate[selectenDestination] = this.mapTemplateValue(
			selectenDestination,
			adContent,
		);

		delete adContentToValidate[TiktokAdContentIdEnum.callToAction];
		delete adContentToValidate[TiktokAdContentIdEnum.destination];

		return adContentToValidate;
	}

	public mapTemplateValue(
		templateId: string,
		adContent: IAdContentDictionary,
	): any {
		if (adContent[templateId]) {
			return adContent[templateId];
		}

		return templateId === TiktokAdContentIdEnum.dynamicCallToAction
			? []
			: '';
	}

	private mapAdPlacement(adPlacement: IAdPlacement): IAdPlacementBaseVM {
		return {
			placementId: adPlacement.placementId,
			creative: adPlacement.creative,
			content: adPlacement?.content,
			isCustomized: adPlacement?.isCustomized,
		};
	}
}
