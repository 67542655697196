import { Injectable, Injector } from '@angular/core';
import { LinkedInAccountFeatureService } from './account/in-account-feature.service';
import { AdFormatsService } from './ad-format/services/ad-formats.service';

@Injectable()
export class LinkedInFeatureService {
	public account: LinkedInAccountFeatureService;
	public adformat: AdFormatsService;

	constructor(private injector: Injector) {
		this.account = this.injector.get(LinkedInAccountFeatureService);
		this.adformat = this.injector.get(AdFormatsService);
	}
}
